import React, { useState } from "react";

import PropTypes from "prop-types";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import Vector1 from "../../images/icons/vector1.svg";
import Vector2 from "../../images/icons/vector2.svg";
import RrrowRight from "../../images/icons/arrow-right.svg";
import clsx from "clsx";

import { usePopperTooltip } from "react-popper-tooltip";
import Typed from "react-typed";
import { Link as ScrollLink } from "react-scroll";
import "./style.scss";
import Typography from "../Typography";
import Card from "../Card";
import CardBody from "../CardBody";

const TabSection = ({ sectionsTab }) => {
  const [tabState, setTabState] = useState(0);

  return (
    <div className={clsx("container", "sectionTabContainer", `sectionTabContainer-${tabState}`)}>
      <div className="sectionsTab">
        <Typography className="sectionsTab-tittle" variant="tittle-2" color="black" weight="semi-bold">
          {sectionsTab?.tittle}
        </Typography>

        <div className="sectionsTab-tab">
          {sectionsTab?.items.map((item, index) => {
            return (
              <>
                <Typography
                  key={index}
                  onClick={setTabState.bind(this, index)}
                  variant="tabItem"
                  weight="semi-bold"
                  className={clsx("sectionsTab-tab-item", `sectionsTab-tab-item${index === tabState ? "-active" : ""}`)}
                >
                  {item.tab}
                  {index === tabState ? (
                    <Vector1 className="sectionsTab-tab-item-svg"></Vector1>
                  ) : (
                    <Vector2 className="sectionsTab-tab-item-svg"></Vector2>
                  )}
                </Typography>
              </>
            );
          })}
        </div>

        {sectionsTab?.items.map(({ logo, img, tittle, introduction, buttons }, index) => {
          if (index === tabState) {
            return (
              <div key={tittle} className="sectionsTab-content">
                <div className="sectionsTab-content-left">
                  <div className="sectionsTab-content-left-logo">
                    <PreviewCompatibleImage imageInfo={{ image: logo }} />
                  </div>
                  <div className="sectionsTab-content-left-tittle">
                    <Typography color="black" weight="semi-bold" variant="tabTittle">
                      {tittle}
                    </Typography>
                  </div>
                  <div className="sectionsTab-content-left-introduction">
                    <Typography color="black" variant="paragraph-1">
                      {introduction}
                    </Typography>
                  </div>
                  <div className="sectionsTab-content-left-buttons">
                    {(buttons ?? []).map((button) => {
                      if (button?.external) {
                        return (
                          <a
                            key={button?.to}
                            href={button?.to}
                            title={button?.title}
                            className={clsx(
                              "btn btn-size-3 rounded-pill align-items-end mr-8 ",
                              `btn-${button.color ?? "primary"}`
                            )}
                            target={button?.newWindow ? "_blank" : null}
                            rel={button?.newWindow ? "noreferrer" : null}
                          >
                            <span className="btn-label">
                              {button?.label}
                              <RrrowRight width={24}></RrrowRight>
                            </span>
                          </a>
                        );
                      }

                      return (
                        <Link
                          key={button?.to}
                          to={button?.to}
                          title={button?.title}
                          className={clsx(
                            "btn btn-has-overlay btn-size-3 rounded-pill align-items-end mr-8",
                            `btn-${button.color ?? "primary"}`
                          )}
                          target={button?.newWindow ? "_blank" : null}
                          rel={button?.newWindow ? "noreferrer" : null}
                        >
                          <span
                            className="btn-overlay"
                            style={{ width: button?.label?.length * 10 + 60, height: button?.label?.length * 10 + 60 }}
                          />
                          <span className="btn-label">{button?.label}</span>
                        </Link>
                      );
                    })}
                  </div>
                </div>
                <div className="sectionsTab-content-right">
                  <div className="sectionsTab-content-right-mask"></div>
                  <div className="sectionsTab-content-right-img">
                    <PreviewCompatibleImage imageInfo={{ image: img }} />
                  </div>
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

TabSection.propTypes = {
  sectionsTab: PropTypes.object,
};

TabSection.defaultProps = {
  sectionsTab: [],
};

export default TabSection;
