import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import NewFooter from "../../components/NewFooter";
import Layout from "../../components/Layout";
import Grid from "../../components/Grid";
import Typography from "../../components/Typography";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import Button from "../../components/Button";
import HomeBanner from "../../components/HomeBanner";
import RrrowRight from "../../images/icons/arrow-right.svg";
import { height } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import TabSection from "../../components/TabSection";
import navigation from "../../data/navigation.json";
import Alogo from "../../components/Alogo";
import AlogoPhone from "../../components/Alogo/Phone/AlogoPhone";
import "./style.scss";
import useLayoutHook from "../../hooks/useLayoutHook";
import Partners from "../../components/Partners";

const getSectionItem = (lang) => {
  switch (lang) {
    case "kr":
      return [
        { describe: "커뮤니티가 변경 제안하고 주도할 수 있도록 허용" },
        { describe: "커뮤니티가 미래를 영향하고 모양을 결정할 수 있도록 허용" },
        { describe: "프로토콜의 건강과 장기 성장을 보장" },
      ];
    case "fr":
      return [
        { describe: "Permettre à la communauté de proposer et de piloter des changements" },
        { describe: "Permettre à la communauté d'influencer et de façonner l'avenir" },
        { describe: "Assurer la santé et la croissance à long terme du protocole" },
      ];
    default:
      return [
        { describe: "Allow the community to propose and drive changes" },
        { describe: "Enable the community to influence and shape the future" },
        { describe: "Ensure the healthiness and long term growth of the protocol" },
      ];
  }
};

export const HomeTemplate = ({
  seo,
  sectionsTab,
  sections,
  sectionsExtra1,
  sectionsExtra2,
  partners,
  location,
  banner,
  cta,
}) => {
  const phoneLayout = useLayoutHook();
  const urlLanguagePart = (location?.pathname?.split("/") ?? [])[1];

  const language = Object.keys(navigation).includes(urlLanguagePart) ? urlLanguagePart : "en";
  return (
    <div
      style={{
        backgroundColor: "#0e0c24",
      }}
    >
      <Layout
        location={location}
        isFooter={false}
        isHeader={false}
        isNewHeader={true}
        isNewFooter={true}
        headerOptions={{ bgColor: "transparent", variant: "fixed-top" }}
      >
        <Helmet
          title={seo?.title}
          meta={[
            {
              name: "description",
              content: seo?.description,
            },
          ]}
        />
        {!phoneLayout ? <Alogo /> : ""}
        <HomeBanner
          heading={banner?.heading}
          subtitle={banner?.subtitle}
          buttons={banner?.buttons}
          underline={banner?.underline}
          scrollDownLabel={banner?.scrollDownLabel}
          alogoprops={{ colour: "" }}
        />
        {(sections ?? []).map((section, idx) => (
          <Grid
            key={section?.title}
            container
            id={`section-${idx + 1}`}
            className="secondary-banner pb-64"
            component="section"
          >
            <Grid row align="center" direction={idx % 2 === 0 ? "row-reverse" : "row"} justify="between">
              <Grid
                container
                column
                xs={12}
                md={4}
                className={clsx("mb-24", "mb-md-0", `${idx % 2 === 0 ? "offset-md-1" : null}`) + ` secBreak-${idx + 1}`}
              >
                <Typography component="div" variant="dateTittle" className="mb-12">
                  {section?.dateTittle}
                </Typography>
                <Typography component="div" variant="tittle-2" className="mb-12">
                  {section?.title}
                </Typography>
                <Typography
                  component="div"
                  style={{
                    maxWidth: "482px",
                  }}
                  variant="introduction-2"
                >
                  {section?.text}
                </Typography>
                {section?.dateTittle === "02/03" && (
                  <Grid
                    style={{
                      marginBottom: "83px",
                    }}
                    column
                  >
                    {getSectionItem(language).map((item, index) => {
                      return (
                        <Grid
                          style={{
                            marginTop: "44px",
                          }}
                          key={index}
                          row
                          align="center"
                          wrap="nowrap"
                        >
                          <div
                            style={{
                              position: "relative",
                              width: "72px",
                              height: "72px",
                              marginRight: "36px",
                            }}
                          >
                            <PreviewCompatibleImage
                              imageInfo={{ image: `/img/home/group${index + 1}.png` }}
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            />
                            <PreviewCompatibleImage
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                              imageInfo={{ image: `/img/home/rectangle${index + 1}.png` }}
                            />
                          </div>
                          <span>{item?.describe}</span>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                <div className="mt-44">
                  {(section?.buttons ?? []).map((button) => {
                    if (button?.external) {
                      return (
                        <a
                          key={button?.to}
                          href={button?.to}
                          title={button?.title}
                          className={clsx(
                            "btn btn-size-3 rounded-pill align-items-end mr-8 ",
                            `btn-${button.color ?? "primary"}`
                          )}
                          target={button?.newWindow ? "_blank" : null}
                          rel={button?.newWindow ? "noreferrer" : null}
                        >
                          <span className="btn-label">
                            {button?.label}
                            <RrrowRight width={24}></RrrowRight>
                          </span>
                        </a>
                      );
                    }

                    return (
                      <Link
                        key={button?.to}
                        to={button?.to}
                        title={button?.title}
                        className={clsx(
                          "btn btn-has-overlay btn-size-3 rounded-pill align-items-end mr-8",
                          `btn-${button.color ?? "primary"}`
                        )}
                        target={button?.newWindow ? "_blank" : null}
                        rel={button?.newWindow ? "noreferrer" : null}
                      >
                        <span
                          className="btn-overlay"
                          style={{ width: button?.label?.length * 10 + 60, height: button?.label?.length * 10 + 60 }}
                        />
                        <span className="btn-label">{button?.label}</span>
                      </Link>
                    );
                  })}
                </div>
              </Grid>
              <Grid column md={6}>
                {/* <div  data-aos="fade-up">
                <PreviewCompatibleImage imageInfo={{ image: section?.visual, alt: section?.title }} />
              </div> */}
                <div
                  style={{
                    maxWidth: "466px",
                    margin: "0 auto",
                  }}
                >
                  {phoneLayout ? <AlogoPhone colour={1 + idx} /> : ""}
                </div>
              </Grid>
            </Grid>
          </Grid>
        ))}
        {sectionsExtra1.map((item, index) => {
          return (
            <Grid className="extraContainer" container key={index}>
              <Grid className={"finalBreak"} row>
                <Grid column className="offset-md-2">
                  <div
                    style={{
                      maxWidth: "804px",
                    }}
                  >
                    <Typography component="div" variant="tittle-1" weight="thin">
                      {item.tittle1}
                    </Typography>
                  </div>
                  <Grid
                    style={{
                      marginTop: "38px",
                    }}
                  >
                    {(item?.buttons ?? []).map((button) => {
                      if (button?.external) {
                        return (
                          <a
                            key={button?.to}
                            href={button?.to}
                            title={button?.title}
                            className={clsx(
                              "btn btn-size-3 rounded-pill align-items-end mr-8 ",
                              `btn-${button.color ?? "primary"}`
                            )}
                            target={button?.newWindow ? "_blank" : null}
                            rel={button?.newWindow ? "noreferrer" : null}
                          >
                            <span className="btn-label">
                              {button?.label}
                              <RrrowRight width={24}></RrrowRight>
                            </span>
                          </a>
                        );
                      }

                      return (
                        <Link
                          key={button?.to}
                          to={button?.to}
                          title={button?.title}
                          className={clsx(
                            "btn btn-has-overlay btn-size-3 rounded-pill align-items-end mr-8",
                            `btn-${button.color ?? "primary"}`
                          )}
                          target={button?.newWindow ? "_blank" : null}
                          rel={button?.newWindow ? "noreferrer" : null}
                        >
                          <span
                            className="btn-overlay"
                            style={{
                              width: button?.label?.length * 10 + 60,
                              height: button?.label?.length * 10 + 60,
                            }}
                          />
                          <span className="btn-label">{button?.label}</span>
                        </Link>
                      );
                    })}
                  </Grid>
                  <div
                    style={{
                      maxWidth: "695px",
                      marginTop: "32px",
                      float: "right",
                    }}
                  >
                    <Typography component="div" variant="tittle-1" weight="thin">
                      {item.tittle2}
                    </Typography>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "-80px",
                      left: "1vw",
                      zIndex: "-1",
                    }}
                  >
                    <PreviewCompatibleImage imageInfo={{ image: item.icon }} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
        <TabSection sectionsTab={sectionsTab}></TabSection>

        <Grid container className="sectionsExtra2">
          <Grid row justify="center" className="sectionsExtra2-tittle">
            <Typography component="div" variant="tittle-2" weight="thin">
              {sectionsExtra2.tittle}
            </Typography>
          </Grid>
          <Grid row justify="center" className="sectionsExtra2-btn">
            {(sectionsExtra2?.buttons ?? []).map((button, index) => {
              if (button?.external) {
                return (
                  <a
                    key={button?.to}
                    href={button?.to}
                    title={button?.title}
                    className={clsx(
                      "btn btn-size-3 rounded-pill align-items-end mr-8 ",
                      `btn-${button.color ?? "primary"}`
                    )}
                    target={button?.newWindow ? "_blank" : null}
                    rel={button?.newWindow ? "noreferrer" : null}
                  >
                    <span className="btn-label">{button?.label}</span>
                  </a>
                );
              }

              return (
                <Link
                  key={button?.to}
                  to={button?.to}
                  title={button?.title}
                  className={clsx(
                    "btn btn-has-overlay btn-size-3 rounded-pill align-items-end mr-8",
                    `btn-${button.color ?? "primary"}`
                  )}
                  target={button?.newWindow ? "_blank" : null}
                  rel={button?.newWindow ? "noreferrer" : null}
                >
                  <span
                    className="btn-overlay"
                    style={{ width: button?.label?.length * 10 + 60, height: button?.label?.length * 10 + 60 }}
                  />
                  <span className="btn-label">{button?.label}</span>
                </Link>
              );
            })}
          </Grid>
          <Grid row justify="center" className="sectionsExtra2-content">
            {(sectionsExtra2?.items ?? []).map((item, index) => {
              return (
                <div
                  key={index}
                  data-aos="zoom-in-up"
                  data-aos-anchor-placement="bottom-bottom"
                  data-aos-easing="ease-in-out"
                  data-aos-duration="500"
                  className={clsx(`sectionsExtra2-content-card`, `sectionsExtra2-content-card-${index + 1}`)}
                >
                  <div className="sectionsExtra2-content-card-headTittle">
                    <Typography component="div" variant="cardHeadTittle">
                      {item.headTittle}
                    </Typography>
                  </div>
                  <div className="sectionsExtra2-content-card-tittle">
                    <Typography component="div" variant="introduction-1">
                      {item.tittle}
                    </Typography>
                  </div>
                  <div className="sectionsExtra2-content-card-introduction">
                    <Typography component="div" align="center" color="white" variant="introduction-2">
                      {item.introduction}
                    </Typography>
                  </div>
                  <div
                    className={clsx("sectionsExtra2-content-card-bgs", `sectionsExtra2-content-card-bgs-${index + 1}`)}
                  >
                    {(item?.bgs ?? []).map((bg, index) => {
                      return <PreviewCompatibleImage key={index} imageInfo={{ image: bg?.image }} />;
                    })}
                  </div>
                </div>
              );
            })}
          </Grid>
        </Grid>
        <Partners partners={partners} />
        {/* <Grid container className="pt-154 pb-96 partners">
          <Grid row justify="center">
            <Typography variant="tittle-2" color="white" component="div" className="mb-40">
              {partners?.title}
            </Typography>
          </Grid>

          <Grid
            style={{
              marginTop: "104px",
            }}
            data-aos="fade-down"
            row
            justify="center"
            className="partners-row"
          >
            <div
              className="partners-row-item"
              style={{ width: "100%", minWidth: 80, maxWidth: 145, margin: "0 60px" }}
            ></div>
            {(partners?.logos.slice(0, 5) ?? [])?.map((logo, index) => (
              <PreviewCompatibleImage
                key={index}
                imageInfo={{ image: logo?.image, alt: logo?.title }}
                style={{ width: "100%", minWidth: 80, maxWidth: 145, margin: "0 60px" }}
                className="partners-row-item"
              />
            ))}
            <div />
          </Grid>
          <Grid
            style={{
              marginTop: "48px",
            }}
            data-aos="fade-up"
            row
            justify="center"
            className="partners-row"
          >
            {(partners?.logos.slice(5, 10) ?? [])?.map((logo, index) => (
              <PreviewCompatibleImage
                key={index}
                imageInfo={{ image: logo?.image, alt: logo?.title }}
                style={{ width: "100%", minWidth: 80, maxWidth: 145, margin: "0 60px" }}
                className="partners-row-item"
              />
            ))}
            <div
              className="partners-row-item"
              style={{ width: "100%", minWidth: 80, maxWidth: 145, margin: "0 60px" }}
            ></div>
          </Grid>
        </Grid> */}
      </Layout>
    </div>
  );
};

HomeTemplate.propTypes = {
  sectionsTab: PropTypes.object,
  seo: PropTypes.object,
  banner: PropTypes.object,
  sections: PropTypes.array,
  sectionsExtra1: PropTypes.array,
  sectionsExtra2: PropTypes.object,
  partners: PropTypes.object,
  cta: PropTypes.object,
  location: PropTypes.object,
};

HomeTemplate.defaultProps = {
  seo: {},
  banner: {},
  sections: [],
  sectionsExtra1: [],
  sectionsExtra2: {},
  sectionsTab: {},
  partners: [],
  cta: {},
  location: undefined,
};

const HomePage = ({ data, location }) => {
  const { frontmatter } = data?.markdownRemark || {};
  const { seo, home } = frontmatter ?? {};
  const { sections, sectionsExtra1, sectionsExtra2, sectionsTab, partners, banner, cta } = home ?? {};
  return (
    <HomeTemplate
      sectionsTab={sectionsTab}
      sectionsExtra1={sectionsExtra1}
      sectionsExtra2={sectionsExtra2}
      seo={seo}
      sections={sections}
      banner={banner}
      partners={partners}
      location={location}
      cta={cta}
    />
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
  location: PropTypes.object,
};

HomePage.defaultProps = {
  data: {
    markdownRemark: {
      frontmatter: {},
    },
  },
  location: undefined,
};

export default HomePage;

export const pageQuery = graphql`
  query homePage($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        seo {
          title
          description
        }
        home {
          banner {
            heading
            scrollDownLabel
            underline {
              text
              linkText
              popperText
            }
            buttons {
              color
              label
              title
              to
              external
              newWindow
            }
          }
          sections {
            buttons {
              color
              label
              title
              to
              external
              newWindow
            }
            logo {
              publicURL
            }
            dateTittle
            text
            title
            visual {
              childImageSharp {
                fluid(maxWidth: 200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          sectionsExtra1 {
            tittle1
            tittle2
            icon
            buttons {
              color
              label
              title
              to
              external
              newWindow
            }
          }
          sectionsExtra2 {
            tittle
            buttons {
              color
              label
              title
              to
              external
              newWindow
            }
            items {
              headTittle
              tittle
              introduction
              bgs {
                image
              }
            }
          }
          sectionsTab {
            tittle
            items {
              tab
              logo
              tittle
              introduction
              img
              buttons {
                label
                title
                color
                to
                external
                newWindow
              }
            }
          }
          cta {
            title
            subtitle
            button {
              label
              to
              external
              newWindow
            }
          }
          partners {
            title
            partnerList {
              title
              logos {
                image {
                  childImageSharp {
                    fluid(maxWidth: 200, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                imageGray {
                  childImageSharp {
                    fluid(maxWidth: 200, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                title
                url
              }
            }
          }
        }
      }
    }
  }
`;
